/*
*    Name       : Custom SCSS Stylesheet
*    Author     : @Corilla
*/

#block-block-4{
    margin-bottom: 50px;
    overflow-x: hidden;
    position: relative;
    
    #back{
        width: 100%;
        min-height: 470px;
        position: relative;
    }
    
    #bici1{
        top: 300px;
        position: absolute;
    }
    
    #bici2{
        top: 270px;
        position: absolute;
    }
    
    #bici3{
        top: 270px;
        position: absolute;
    }
    
    #bici4{
        top: 250px;
        position: absolute;
    }
    @media screen and (max-width: 992px) {
        display: none;
    }
}